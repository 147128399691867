<template>
  <div id="man">
    <div v-if="isLoading" class="container py-3">
      <div class="spinner-border text-muted"></div>
    </div>
    <template v-else-if="!error">
      <ul id="nav" class="nav nav-pills container my-3">
        <li class="nav-item">
          <router-link class="nav-link" active-class="active" to="./">{{ $t('label.home') }}</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" active-class="active" to="rule">{{ $t('label.rule') }}</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" active-class="active" to="check-in">{{ $t('label.checkin') }}</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" active-class="active" to="tables">{{ $t('label.table') }}</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" active-class="active" to="leaderboard">{{ $t('label.leaderboard') }}</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" active-class="active" to="config">{{ $t('label.config') }}</router-link>
        </li>
      </ul>
      <router-view/>
    </template>
    <template v-else-if="error.response.status == 404">
      <div class="container py-3">
        <error-404/>
      </div>
    </template>
    <template v-else-if="error.response.status >= 500">
      <div class="container py-3">
        <error-500/>
      </div>
    </template>
    <template v-else>
      <div class="container py-3">
        <h3>Error!</h3>
      </div>
    </template>
  </div>
</template>
<script>
import axios from 'axios'
import event from '@/store/event'

export default {
  components: {
    Error404: () => import('./Error404.vue'),
    Error500: () => import('./Error500.vue'),
  },
  data() {
    return {
      error: null,
      isLoading: false,
    }
  },
  computed: {
    isWaitingRefresh() {
      return this.$store.state.isWaitingRefresh
    },
    lang() {
      return this.$route.params.lang
    }
  },
  created() {
    this.isLoading = true
    this.fetchData(this.$route.params.id)
    .then(data => {
      if(this.$store.hasModule('event')) {
        this.$store.unregisterModule('event')
      }
      this.$store.registerModule('event', event)
      this.$store.dispatch('event/setEvent', { id: this.$route.params.id, phrase: data.phrase, created: data.created })
      this.$store.dispatch('event/restoreData', data)
    })
    .catch(err => {
      this.error = err
      console.error(err)
    })
    .finally(() => {
      this.isLoading = false
    })
  },
  methods: {
    async fetchData(key) {
      this.isLoading = true

      const resp = await axios.get(process.env.VUE_APP_BACKEND_HOST + '/api/man/' + key)
      return resp.data
    },
  }
}
</script>
<style>
#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav .active:not(.router-link-exact-active) {
  background-color: transparent;
}

#nav .nav-link.router-link-exact-active {
  /* color: hsl(var(--primary-HS), 41%); */
  background-color: var(--primary-color);
  color: white;
}

</style>
<i18n>
{
  "en": {
    "message": {
      "doneRestore": "Restored!"
    }
  },
  "ja": {
    "message": {
      "doneRestore": "復元しました。"
    }
  }
}
</i18n>
